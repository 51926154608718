import styled from '@emotion/styled'

export const Button = styled.button`
  padding: 32px;
  min-width: 500px;
  @media all and (max-width: 600px) {
    min-width: 200px;
  }
  background-color: white;
  font-size: 24px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  &:hover {
    color: grey;
  }
`
export const Title = styled.h1``
export const InputField = styled.input `
  
`
export const SendMessageDisplay = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
`
export const TextArea = styled.textarea `
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    border-radius: 3,
    box-shadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 400px,
    width: 200px;
    padding: '0 30px',
    fontSize: '20px',
    color: 'blue',
    resize: 'none',
  `