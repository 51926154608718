import React, { useState } from 'react'
import './App.css'
import {Button, TextArea, InputField, SendMessageDisplay, Title} from './App.styles.js'
const host = 'https://oci.guardian.aviture.dev/pecos-sim'

const start = () => {
  fetch(`${host}/startSim`)
}
const pause = () => {
  fetch(`${host}/stopSim`)
}
const reset = () => {
  fetch(`${host}/hardReset`)
}
const sendMessage = (sender, receiver, message)=> {
  fetch(`${host}/sendMessage?fromImei=${sender}&toImei=${receiver}&message=${message}`)
}
function App() {
  const [sender, setSender] = useState(100000000081002)
  const [receiver, setReceiver] = useState(888798183166406)
  const [message, setMessage] = useState('')
  return (
    <div className="App">
      <header className="App-header">
        <Title>PECOS SIMULATOR CONTROLS</Title>
        <Button onClick={start}>Start</Button>
        <a href="https://oci.guardian.aviture.dev/pecos-sim/startSim" target="_blank">Start Sim</a>
        <Button onClick={pause}>Pause</Button>
        <a href="https://oci.guardian.aviture.dev/pecos-sim/stopSim" target="_blank">Stop Sim</a>
        <Button onClick={reset}>Reset</Button>
        <a href="https://oci.guardian.aviture.dev/pecos-sim/hardReset" target="_blank">Hard Reset</a>
        
        <SendMessageDisplay>
          <div>Send Message</div>
          <InputField type="number" placeholder="IMEI Sender" onChange={(event) => setSender(event.target.value)}>
          </InputField>
          <InputField type="number" placeholder="IMEI Receiver" onChange={(event) => setReceiver(event.target.value)}>
          </InputField>
          <TextArea placeholder="Enter your message" onChange={(event)=>setMessage(event.target.value)}></TextArea>
          <button onClick={()=> sendMessage(sender, receiver, message)}>Send Message</button>
        </SendMessageDisplay>


      </header>
    </div>
  );
}

export default App;
